import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const HeaderContainer = styled.div`
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 1em;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: 960px) {
    justify-content: space-between;
  }
`

const LogoWrapper = styled.div``

const LogoLink = styled(props => <Link {...props} />)`
  font-family: "HermesRough";
  font-size: ${props => props.theme.fontSizes.headerLogo};
  margin-right: 1em;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const MainNavList = styled.ul`
  text-align: center;

  @media (max-width: 960px) {
    text-align: left;
  }
`

const NavLinkMain = styled(props => <Link {...props} />)`
  display: inline-block;
  margin-left: 1em;
  font-family: "HermesRough";
  font-size: ${props => props.theme.fontSizes.headerLogo};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  @media (max-width: 960px) {
    margin-left: 0em;
    margin-right: 0.5em;
  }
`

const NavLinkLast = styled(props => <Link {...props} />)`
  display: inline-block;
  margin-left: 1em;
  font-family: "HermesRough";
  font-size: ${props => props.theme.fontSizes.headerLogo};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  @media (max-width: 960px) {
    margin-left: 0em;
  }
`

const NavLinkImgWrapper = styled.a`
  display: inline-block;
  margin-left: 1em;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  @media (max-width: 960px) {
    margin-left: 0em;
    margin-right: 0.5em;
  }

  max-height: 56px;
  line-height: 56px;
`

const NavLinkDiscord = styled.div`
  width: 56px;
  height: 56px;
  position: relative;
  top: 10px;

  background-color: white;
  -webkit-mask: url(/Discord-Logo-White.svg) no-repeat center;
  mask: url(/Discord-Logo-White.svg) no-repeat center;

  &:hover {
    background-color: ${props => props.theme.colors.brandColorBright};
  }
`

const NavLinkTwitter = styled.div`
  width: 56px;
  height: 56px;
  position: relative;
  top: 10px;

  background-color: white;
  -webkit-mask: url(/Twitter_Social_Icon_Circle_White.svg) no-repeat center;
  mask: url(/Twitter_Social_Icon_Circle_White.svg) no-repeat center;

  &:hover {
    background-color: ${props => props.theme.colors.brandColorBright};
  }
`

const NavLinkInstagram = styled.img`
  width: 56px;
  height: 56px;
  position: relative;
  top: 10px;

  &:hover {
    filter: invert(86%) sepia(33%) saturate(488%) hue-rotate(97deg)
      brightness(101%) contrast(101%);
  }
`

export default function Header() {
  return (
    <HeaderContainer>
      <LogoWrapper>
        <LogoLink to="/">GHOSTBUTTER</LogoLink>
      </LogoWrapper>
      <MainNavList>
        {/* <NavLinkImgWrapper alt="" href="#">
          <NavLinkDiscord />
        </NavLinkImgWrapper>
        <NavLinkImgWrapper alt="" href="#">
          <NavLinkTwitter />
        </NavLinkImgWrapper>
        <NavLinkImgWrapper alt="" href="#">
          <NavLinkInstagram href="/glyph-logo_May2016.png" />
        </NavLinkImgWrapper> */}
        <NavLinkMain to="/about">ABOUT</NavLinkMain>
        <NavLinkLast to="/contact">CONTACT</NavLinkLast>
      </MainNavList>
    </HeaderContainer>
  )
}
