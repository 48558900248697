import React from "react"
import { createGlobalStyle } from "styled-components"
import { Reset } from "styled-reset"
import Theme from "../themes/theme"

const GlobalStyle = createGlobalStyle`

html {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 22px;
  }
}

  h1, h2, h3, p, li, a {
    font-family: ${Theme.fonts.defaultFont};
  }

  p {
    font-size: ${Theme.fontSizes.defaultFont};
  }

  body {
    background-color: ${Theme.colors.defaultBackground};
    color: ${Theme.colors.defaultText};

    *::-moz-selection { color: ${Theme.colors.defaultBackground} !important;  background: ${Theme.colors.brandColorBright} !important; }
    *::selection      { color: ${Theme.colors.defaultBackground} !important;  background: ${Theme.colors.brandColorBright} !important; }
  }

  a {
    color: ${Theme.colors.defaultText};
    text-decoration: none;
  }

  a:hover {
    color: ${Theme.colors.brandColorBright};
  }

  i {
    font-style: italic;
  }

  b {
    font-weight: 800;
  }

  .swiper-button-next, .swiper-button-prev {
    color: white;
  }

  .swiper-button-disabled {
    opacity: .15 !important;
  }
`

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <Reset />
      <GlobalStyle theme={Theme} />
      {children}
    </React.Fragment>
  )
}
