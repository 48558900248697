import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const FooterLayout = styled.div`
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 1em;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  @media (min-width: 960px) {
    justify-content: space-between;
  }
`
const FooterParagraph = styled.p`
  color: ${props => props.theme.colors.reducedText};
  font-family: ${props => props.theme.fonts.defaultFontItalic};
  font-style: italic;
  font-size: ${props => props.theme.fontSizes.p};
`

export default function Footer() {
  return (
    <FooterLayout>
      <FooterParagraph>
        © Ghostbutter {new Date().getFullYear()}
      </FooterParagraph>
    </FooterLayout>
  )
}
