import React from "react"
import styled from "styled-components"

const StyledMainContainer = styled.div`
  padding: 20px;
  margin: 0;
  list-style: none;
  display: block;
  margin: 0 auto;
  max-width: 1200px;
`

export default function MainContainer({ children }) {
  return <StyledMainContainer>{children}</StyledMainContainer>
}
